<script lang="ts" setup>
const img = useImage();
const config = useGlobyConfig();
</script>

<template>
  <header>
    <v-carousel :show-arrows="false" cycle height="200px" hide-delimiters>
      <v-carousel-item
        v-for="imgLoc of config.general.bannerImgs"
        :key="imgLoc"
        :src="img('/header/' + imgLoc)"
        cover
      />
    </v-carousel>
  </header>
</template>

<style scoped></style>
